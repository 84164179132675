import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc, documentId } from "firebase/firestore"
import getCollectionPath from '@/assets/functions/getCollectionPath'
import { useAppStore } from '@/stores/appdata'

let db = getFirestore()
let collectionName = 'logs/'

function docToInstance(document) {
  let data = document.data()
  return data ? new Log(document.id, data.user, data.logs, data.pings) : null
}

export default class Log {
  constructor(id, user, logs, pings) {
    this.id = id
    this.user = user
    this.logs = logs
    this.pings = pings
  }

  static initOne(userId){
    let instance = new Log(null,userId,[],[])
    return instance
  }

  static async getNewId() {
    const newDocRef = doc(collection(db, await getCollectionPath(collectionName)))
    return newDocRef.id
  }

  async save() {
    const new_log = {
      user: this.user,
      logs: this.logs,
      pings: this.pings,
    }

    if (this.id) {
      await setDoc(doc(db, await getCollectionPath(collectionName), this.id), new_log)
    } else {
      const res = await addDoc(collection(db, await getCollectionPath(collectionName)), new_log)
      this.id = res.id
    }
  }

  async delete() {
    await deleteDoc(doc(db, await getCollectionPath(collectionName), this.id))
  }

  static async listenAll(callback) {
    onSnapshot(collection(db, await getCollectionPath(collectionName)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }

  static async listenById(id, callback) {
    onSnapshot(doc(db, await getCollectionPath(collectionName), id), snapshot => {
      callback(docToInstance(snapshot))
    })
  }
  
  static async listenByUser(userId, callback) {
    return onSnapshot(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)), snapshot => {
      const list = []
      snapshot.forEach(doc => {
        list.push(docToInstance(doc))
      })
      callback(list)
    })
  }

  static async getAll() {
    const documents = await getDocs(collection(db, await getCollectionPath(collectionName)))
    return documents.docs.map(docToInstance)
  }

  static async getById(id) {
    const document = await getDoc(doc(db, await getCollectionPath(collectionName), id))
    return docToInstance(document)
  }
  
  static async getByUser(userId){
    let response = await getDocs(query(collection(db, await getCollectionPath(collectionName)), where("user", "==", userId)))
    let list = []
    response.forEach(doc => {
        list.push(docToInstance(doc))
    })
    return list
  }
}