import firebaseConfig from '@/config/firebase'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import vuetify from './plugins/vuetify'
import { createPinia } from 'pinia'
import { loadFonts } from './plugins/webfontloader'
import { AVPlugin } from "vue-audio-visual"
import p5vue from "p5vue"

import "@/styles/styles.scss"
import "@/styles/swal.scss"
document.addEventListener("DOMContentLoaded", async () => {
  loadFonts()
  let pinia = createPinia()

  createApp(App)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(AVPlugin)
  .use(p5vue)
  .mount('#app')
})
