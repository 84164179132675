<template>
  <v-app v-if="(isReady >= 5 && profileStore.profile && profileStore.settings && profileStore.userData && appStore.app && appStore.appdata) || !$route.meta.accountNeeded">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <v-app v-else>
    <v-main>
      <v-img src="/loading.png" cover style="height: 100%; width: 100%; position: absolute; top: 0; left: 0;"></v-img>
      <v-container class="py-0 d-flex flex-column align-center justify-center" style="height: 100%; max-width:100%;" v-if="$vuetify.display.mdAndUp">
        <v-card class="pa-10" style="border-radius: 50px; opacity: .9;">
          <h1 class="text-primary mb-3">Chargement ...</h1>
          <v-progress-circular color="primary" indeterminate :size="200" :width="10">
            <v-img class="mx-2" height="125" width="125" :src="require('@/assets/images/Logo.svg')"></v-img>
          </v-progress-circular>
        </v-card>
      </v-container>
      <v-container class="py-0 d-flex flex-column align-center justify-center" style="height: 100%; max-width:100%;" v-else>
        <v-card class="pa-10" style="border-radius: 50px; opacity: .9;">
          <h3 class="text-primary mb-3">Chargement ...</h3>
          <v-progress-circular color="primary" indeterminate :size="100" :width="6">
            <v-img class="mx-2" height="70" width="70" :src="require('@/assets/images/Logo.svg')"></v-img>
          </v-progress-circular>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getAuth } from '@firebase/auth'

import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/appdata'

import packageJSON from '@/../package.json'
import { useTheme } from 'vuetify'

import initAppdata from '@/assets/functions/initAppdata'
import logger from '@/assets/functions/logger'

import Profile from '@/classes/Profile'
import Application from '@/classes/Application'
import Appdata from '@/classes/Appdata'
import Settings from '@/classes/Settings'
import UserData from '@/classes/UserData'

export default {
  data() {
    return {
      unsub: [],
      nesteadUnsub: [],

      profileStore: useProfileStore(),
      appStore: useAppStore(),

      isReady: 0,
      isOnGoodVersion: true,
      theme: useTheme(),
    }
  },
  async mounted(){
    this.nesteadUnsub = []
    getAuth().onAuthStateChanged(async user => {
      if (user) {
        this.unsub.push(await Profile.listenById(user.uid, async profile => {
          if (profile && profile.currentApps != undefined && profile.currentApps.app_valy != undefined) {

            this.profileStore.profile = profile
            
            if (profile.role !== 'SuperAdmin') {
              this.profileStore.profile.role = profile.organisations[profile.currentOrganisation].role
            }

            this.isReady++

            for (let unsub of this.nesteadUnsub) {
              if (typeof unsub === 'function') {
                unsub()
              }
            }
            this.nesteadUnsub.push(await Appdata.listenByApplication(profile.currentApps.app_valy, async appdata => {
              this.appStore.appdata = appdata
              if (!this.appStore.appdata.parameters) {
                this.appStore.appdata = initAppdata(this.appStore.appdata)
                await this.appStore.appdata.save()
              }
              else if(!this.appStore.appdata.parameters.version){
                this.appStore.appdata.parameters.version = parseInt(packageJSON.version.split('.')[0])
                await this.appStore.appdata.save()
              }

              if(this.appStore.appdata.parameters.version < parseInt(packageJSON.version.split('.')[0])){
                this.isOnGoodVersion = false
              }else{
                this.isOnGoodVersion = true
              }

              if(!this.isOnGoodVersion){
                this.$router.push('/BadVersion')
              }

              this.isReady++
            }))
            this.nesteadUnsub.push(await Application.listenById(profile.currentApps.app_valy, async application => {
              this.appStore.app = application
              if (this.appStore.app) {
                if (this.appStore.app.archived) {
                  this.$router.push('/NoCurrentApp')
                }
              }
              
              this.nesteadUnsub.push(await Settings.listenByUser(profile.id, async settings => {
                if(settings.length == 0){
                  let settings = Settings.initOne(profile.id)
                  await settings.save()
                }
                if(settings.length > 1){
                  for(let i = 1; i < settings.length; i++){
                    await settings[i].delete()
                  }
                }
                if(settings.length > 0){
                this.profileStore.settings = settings[0]
                  this.isReady++
                }
              }))

              this.nesteadUnsub.push(await UserData.listenByUser(profile.id, async userData => {
                if(userData.length == 0){
                  let userData = UserData.initOne(profile.id)
                  await userData.save()
                }
                if(userData.length > 1){
                  for(let i = 1; i < userData.length; i++){
                    await userData[i].delete()
                  }
                }
                if(userData.length > 0){
                  let tmp_old_notif = userData[0].notifs.filter((notif) => notif.read)
                  userData[0].notifs = userData[0].notifs.filter((notif) => !notif.read)
                  
                  if(tmp_old_notif.length > 0){
                    await userData[0].save()
                  }

                  this.profileStore.userData = userData[0]
                }
                this.isReady++
              }))

              this.isReady++
            }))

          }else{
            if(!profile){
              this.logout()
            }
            else if(profile.currentApps == undefined || profile.currentApps.app_valy == undefined){
              this.$router.push('/NoCurrentApp')
            }else{
              this.logout()
            }
          }
        }))
      }
      else this.logout()
    })
  },
  methods: {
    logout() {
      for (let unsub of this.unsub) {
        if (typeof unsub === 'function') {
          unsub()
        }
      }
      for (let unsub of this.nesteadUnsub) {
        if (typeof unsub === 'function') {
          unsub()
        }
      }

      this.profileStore.profile = null
      this.appStore.app = null

      if (!this.$route.meta.accessRules || this.$route.meta.accessRules.accountNeeded) {
        this.$router.push('/login')
      }
    }
  },
  beforeUnmount() {
    for (let unsub of this.unsub) {
      if (typeof unsub === 'function') {
        unsub()
      }
    }
    for (let unsub of this.nesteadUnsub) {
      if (typeof unsub === 'function') {
        unsub()
      }
    }
  }
}
</script>
