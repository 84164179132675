import Log from '@/classes/Log'

let logger = {
  async log(userId, type, target, message) {
    let currentLog = await Log.getByUser(userId)

    if (currentLog.length <= 0) {
      currentLog = Log.initOne(userId)
    }else{
      currentLog = currentLog[0]
    }

    currentLog.logs.push({
      date: new Date().getTime(),
      type: type,
      target: target,
      message: message,
    })

    await currentLog.save()
  },

  async ping(userId) {
    let currentLog = await Log.getByUser(userId)

    if (currentLog.length <= 0) {
      currentLog = Log.initOne(userId)
    }else{
      currentLog = currentLog[0]
    }

    currentLog.pings.push(new Date().getTime())

    await currentLog.save()
  },

  async multiPing(userId, dates) {
    let currentLog = await Log.getByUser(userId)

    if (currentLog.length <= 0) {
      currentLog = Log.initOne(userId)
    }else{
      currentLog = currentLog[0]
    }
    
    currentLog.pings = currentLog.pings.concat(dates)

    await currentLog.save()
  },

  async downloadLogs(userId) {
    let currentLog = await Log.getByUser(userId)

    if (currentLog.length <= 0) {
      currentLog = Log.initOne(userId)
    }else{
      currentLog = currentLog[0]
    }

    let csvContent = "data:text/csv;charset=utf-8,"
    csvContent += "Date,Type,Message\n"

    currentLog.logs.forEach(log => {
      csvContent += `${new Date(log.date).toLocaleString()},${log.type},${log.message}\n`
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "logs.csv")
    document.body.appendChild(link)
    link.click()
  },

  async downloadPings(userId) {
    let currentLog = await Log.getByUser(userId)

    if (currentLog.length <= 0) {
      currentLog = Log.initOne(userId)
    }else{
      currentLog = currentLog[0]
    }

    let csvContent = "data:text/csv;charset=utf-8,"
    csvContent += "Date\n"

    currentLog.pings.forEach(ping => {
      csvContent += `${new Date(ping).toLocaleString()}\n`
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "pings.csv")
    document.body.appendChild(link)
    link.click()
  }
}

export default logger