import colors from 'vuetify/lib/util/colors'

let themeConfig = {
    dark: true,
    colors: {
        background: '#101016',
        backgroundLight: '#1a1a29',
        backgroundDark: '#08080c',
        surface: '#101622',
        surfaceLight: '#0f2647',
        surfaceDark: '#1a2537',
        tooltipBg: '#03070e',
        primary: '#1793D1',
        secondary: '#7b94b6',
        accent: '#F1C972',
        error: colors.red.accent3,
        info: colors.lime.accent3,
        success: colors.green.accent3,
        warning: colors.deepOrange.lighten1,
    },
}

export default themeConfig;